import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import MapLoader from "../components/map/MapLoader"
import MapMenu from "../components/map/MapMenu"
import Seo from "../components/Seo"

const Map = () => {
  useEffect(() => {
    document.body.classList.add("no-padding")
    return () => document.body.classList.remove("no-padding")
  }, [])

  // Query for maps in folder src/images/map
  const maps = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            gatsbyImageData(
              breakpoints: 10
              placeholder: DOMINANT_COLOR
              quality: 100
            )
            parent {
              ... on File {
                name
                dir
                id
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const allMaps = maps.allImageSharp.edges
    .map(edge => {
      return edge.node
    })
    .sort((a, b) => a.parent.name.localeCompare(b.parent.name))

  // Used for iterating through different maps.
  const [map, setMap] = useState(allMaps[0])

  return (
    <>
      <Seo title="Map" />
      <div className="map-page">
        <MapMenu availableMaps={allMaps} changeMap={image => setMap(image)} />
        <MapLoader map={map} />
      </div>
    </>
  )
}

export default Map
